import React, {useState} from 'react'
import ReactDOM from 'react-dom'

const EmbedVideoFrame = ({ videoLink, title, thumbnail }) => {

    const [videoLinkMSTeams, setVideoLinkMSTeams] = useState('')

    const handleVideo = () => setVideoLinkMSTeams(videoLink)

  return (
    <div className='w-full pt-10 xl:py-16 lg:pb-0 px-2' id="msTeamVideo">
            <div className='max-w-7xl mx-auto'>
                <h2 className='text-center max-w-4xl mx-auto homepage-section-heading'>{title}</h2>
                <div class="container my-10 md:mb-0 lg:mt-10 lg:mb-0 xl:mt-16 xl:mb-10 msteamsVid">              
                    <div className='amazonpaidvideo mx-auto rounded-xl shadow-xl md:shadow-none xl:shadow-xl border border-white relative md:max-w-3xl xl:max-w-7xl'>
                        <iframe className={(`youtube-videos absolute left-0 top-0 right-0 bottom-0 rounded-xl` + ((videoLinkMSTeams.length > 0) ? `block` : `hidden`))} loading="lazy" width="500px" height="500px" src={videoLinkMSTeams} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen=""></iframe>
                        <img className= {(`absolute left-0 top-0 transform xl:-translate-y-12 right-0 bottom-0 cursor-pointer h-52 md:h-auto md:w-10/12 xl:w-full mx-auto ` + ((videoLinkMSTeams.length > 0) ? `hidden` : `block`))} onClick={handleVideo} src={thumbnail} />
                    </div>
                </div>
            </div>
    </div>
  )
}

// const renderEmbedVideoFrame = () => {
//     if(typeof(window) != "undefined"){
//         let id = document.getElementById("embeddedVideo")
//         if(id != null){
//             ReactDOM.render(
//                 <EmbedVideoFrame />, 
//                 id
//             )
//         }
//     }
// }

export default EmbedVideoFrame